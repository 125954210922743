<script>
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
import PageHeader from "@/components/page-header";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";




import axios from 'axios';
import Paginate from 'vuejs-paginate-next';

import {
    authMethods,
    authFackMethods,
    notificationMethods,
} from "@/state/helpers";

export default {

    page: {
        title: "Payment Accounts",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Payment Accounts",
            items: [{
                text: "Payment Accounts",
                href: "/users",
            },
            {
                text: "Payment Accounts",
                active: true,
            },
            ],
            value: ['javascript'],

            date: null,

            submitted: false,
            regError: null,
            regSuccess: null,
            tryingToRegister: false,
            isRegisterError: false,
            registerSuccess: false,
            userserror: false,
            userserrormsg: '',
            usersok: false,
            is_admin: false,
            usersokmsg: '',
            userSearchTerm: '',
            currentpage: 0,
            totalpage: 0,
            totalrows: 0,

            updateuser_id: 0,
            usertype: 'user',
            userstatus: '',
            payment_accounts_list: [],
            payment_holder_name_list: [],
            passerror: false,
            passok: false,
            passerrormsg: '',
            passokmsg: '',

            type: 'Bank Account',
            status: 'Active',
            details: '',
            isadd: true,
            modaltitle: '',
            buttonname: '',

            payment_holder_name_id: '',


        };
    },
    validations: {

    },
    computed: {

    },
    components: {
        Layout,
        paginate: Paginate,
        PageHeader,
        //Multiselect,
        //flatPickr
    }, mounted: function () {
        this.getUserData();
    },
    methods: {
        clickCallback: function (pageNum) {
            // alert("pageNum==" + pageNum);
            this.currentpage = parseInt(pageNum) - 1;
            this.getUserData();

        },
        async getUserData() {

            var result = await axios.post(appConfig.api_url + 'payment_accounts/getall', {
                filter: '' + this.userSearchTerm,
                page: this.currentpage
            })


            this.totalpage = result.data.totalpages;
            this.totalrows = result.data.totalrows;
            this.payment_accounts_list = result.data.data;
            this.payment_holder_name_list = result.data.payment_holder_name;

        },
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        async updateuserprofile() {
            this.submitted = true;
            // stop here if form is invalid

            this.tryingToRegister = true;
            this.regError = null;

            var mode = 'edit';
            if (this.isadd) {
                mode = 'add';
            }

            // alert(appConfig.api_url + '' + goto);

            const result = await axios.post(appConfig.api_url + 'payment_accounts/manage', {
                id: this.updateuser_id,
                mode: mode,
                details: this.details,
                payment_holder_name_id: this.payment_holder_name_id,
                type: this.type,
                status: this.status,
                user_id: parseInt(localStorage.getItem('userid'))
            });

            //alert(JSON.stringify(result.data))

            if (result.data.status == 'errors') {
                this.tryingToRegister = false;
                this.regError = result.data.data.data;
                this.isRegisterError = true;
                // return this.authError = result.data.data.data;
            } else {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                this.regSuccess = 'Payment Account added';
                this.getUserData();
                document.getElementById('closeupdatemodalbutton').click();
            }


        }, async filteruser() {
            this.payment_accounts_list = [];
            this.getUserData();
        }, updateuser(id, payment_holder_name_id, type, details, status) {
            this.modaltitle = 'Update Payment Account';
            this.payment_holder_name_id = payment_holder_name_id;
            this.details = details;
            this.buttonname = 'Update';
            this.isadd = false;
            this.updateuser_id = id;
            this.type = type;
            this.status = status;
            document.getElementById('openupdatemodalbutton').click();
            this.isRegisterError = false;
            this.registerSuccess = false;

        }, createuser() {
            this.isRegisterError = false;
            this.registerSuccess = false;
            this.modaltitle = 'Create new user';
            this.buttonname = 'Add';
            this.isadd = true;
            this.payment_holder_name_id = -1;
            this.updateuser_id = -1;
            this.details = '';
            this.type = 'Bank Account';
            this.status = 'Active';
            document.getElementById('openupdatemodalbutton').click();
        }, async UpdateStatus() {

            //alert(this.updateuser_id + "====" + this.userstatus)
            await axios.post(appConfig.api_url + 'updateUserStatus', {
                Id: this.updateuser_id,
                Status: this.userstatus
            })


            //alert(JSON.stringify(result.data));
            //console.log(JSON.stringify(result));
            // this.userslist = users;
            // document.getElementById('openupdatemodalbutton').click();
            this.usersok = true;
            this.usersokmsg = 'User status updated'
            this.getUserData();


        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-3" style="background-color: white">
            <!-- <h3 class="m-2">Users</h3> -->
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div class="form-icon m-2">
                    <input v-model="userSearchTerm" type="text" @keyup="filteruser"
                        placeholder="Search by name, details, status" class="form-control form-control-icon"
                        aria-label="Recipient's username" aria-describedby="button-addon2">
                    <i class=" ri-search-line"></i>
                </div>
                <div class="page-title-right m-2">
                    <button class="btn btn-success " style="float:right" @click="createuser">+ Create Payment
                        Account</button>
                </div>
            </div>
        </div>

        <form class="needs-validation" @submit.prevent="updateuserprofile" enctype="multipart/form-data">
            <div class="row">
                <div class="card ">


                    <div class="card-body m-2">
                        <div class="row col-lg-12">
                            <b-alert v-model="registerSuccess" class="mb-3" style="padding-bottom:10px" variant="success"
                                dismissible>
                                {{ regSuccess }}
                            </b-alert>
                            <br>
                        </div>

                        <div class="table-responsive table-card">
                            <table class="table align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">Action</th>

                                        <th scope="col">ID</th>
                                        <th scope="col">Person Name</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Details</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user) of payment_accounts_list" v-bind:key="user.id"
                                        :style="user.status == 'Active' ? 'background-color:#ddfcdd' : 'background-color:#ffe5e5'">
                                        <td><a
                                                @click="updateuser(user.id, user.payment_holder_name_id, user.type, user.details, user.status)"><i
                                                    class="ri-edit-2-fill"></i></a>
                                        </td>
                                        <td>{{ user.id }}</td>
                                        <td>{{ user.payment_holder_name }}</td>
                                        <td>{{ user.type }}</td>
                                        <td>{{ user.details }}</td>
                                        <td>{{ user.status }}</td>

                                    </tr>

                                </tbody>

                            </table>
                            <!-- end table -->
                        </div>

                        <div class="mt-4">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                                    <p style="float:left">Rows: {{ totalrows }}</p>

                                    <div class="page-title-right" style="float:right">
                                        <paginate v-model="page" :page-count="totalpage" :margin-pages="2"
                                            :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'"
                                            :container-class="'pagination'" :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!--end tab-pane-->


                    </div>
                </div>
                <!--end col-->
            </div>
        </form>
        <div class="row">

        </div>

        <!-- start of create document edit modal -->
        <a data-bs-toggle="modal" id="openupdatemodalbutton" data-bs-target="#updatecreatedocumentsmodal"></a>
        <div class="modal fade" id="updatecreatedocumentsmodal" tabindex="-1" aria-labelledby="exampleModalgridLabel">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalheader">{{ modaltitle }}</h5>
                        <button type="button" id="closeupdatemodalbutton" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row col-lg-12">
                            <b-alert v-model="isRegisterError" class="mt-3 mb-3" variant="danger" dismissible>{{ regError }}
                            </b-alert>
                        </div>
                        <form @submit.prevent="updateuserprofile" id="updateDocumentform">
                            <div class="row g-3">
                                <div class="col-lg-4">
                                    <div>
                                        <label for="firstName" class="form-label">Payment Holder Name</label>
                                        <select required v-model="payment_holder_name_id" class="form-select mb-3"
                                            aria-label="Default select example">
                                            <option v-for="(user) of payment_holder_name_list" v-bind:key="user.id"
                                                :value="user.id">{{ user.name }}</option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <label for="firstName" class="form-label">Select Type</label>
                                    <select required v-model="type" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option value="Bank Account">Bank Account</option>
                                        <option value="Payoneer">Payoneer</option>
                                        <option value="Money Gram">Money Gram</option>
                                        <option value="Western Union">Western Union</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Strip">Strip</option>
                                        <option value="Jazz Cash">Jazz Cash</option>
                                        <option value="Easy Paisa">Easy Paisa</option>
                                        <option value="Union Pay">Union Pay</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <!--end col-->

                                <div class="col-lg-4">
                                    <div>
                                        <label for="firstName" class="form-label">Select Status</label>
                                        <select required v-model="status" class="form-select mb-3"
                                            aria-label="Default select example">
                                            <option value="Active">Active</option>
                                            <option value="Disabled">Disabled</option>
                                        </select>
                                        <input type="hidden" v-model="updateuser_id">

                                    </div>
                                </div>
                                <div class="col-lg-12">


                                    <textarea required v-model="details" cols="60" rows="5"></textarea>


                                </div>
                                <!--end col-->

                                <!--end col-->
                                <div class="col-lg-12">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" class="btn btn-light"
                                            data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">{{ buttonname }}</button>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!--end row-->
    </Layout>
</template>